import { TextField } from '@mui/material';
import { alpha } from 'src/theme/palette';
import { ArrowRight } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { buttonStateChipColorMap } from 'src/@types/button';
import Chip, { ChipColorType } from 'src/components/core/atoms/Chip';
import Link from 'src/components/core/atoms/Link';
import { Logo } from 'src/components/core/atoms/Logo';
import { ROUTES } from 'src/config';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import useDevice from 'src/hooks/useDevice';
import { FOOTER_SECTIONS } from 'src/navigation';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';
import { getListItemKey } from 'src/utils/format';
import snackbar from 'src/utils/snackbar';
import IconButton from 'src/components/core/atoms/IconButton';

// ----------------------------------------------------------------------

const FOOTER_FOOTNOTES = [
  { name: 'Terms of Service', path: ROUTES.TOS },
  { name: 'Privacy Policy', path: ROUTES.PRIVACY_POLICY },
  { name: 'Compliance', path: ROUTES.COMPLIANCE }
];

function SubscribeBox() {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  const [isHovering, setIsHovering] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className="w-full">
        <div
          className="flex w-full flex-col items-start"
          style={{
            borderColor: palette.border.main
          }}
        >
          <div className="mb-2 flex w-full flex-grow items-center">
            <TextField
              disabled={isLoading}
              fullWidth
              autoComplete="off"
              placeholder="name@example.com"
              color="info"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              InputProps={{
                sx: {
                  borderRadius: 4,
                  px: 2
                },
                endAdornment: (
                  <IconButton
                    className="relative max-h-10 min-h-10 min-w-10 max-w-10 cursor-pointer rounded-3xl"
                    style={{
                      backgroundColor: alpha(
                        palette.primary.main,
                        0.04
                      ),
                      transition: 'all 0.2s ease-in-out',
                      ['--icon-button-bg-color-hover' as string]:
                        alpha(palette.primary.main, 0.08)
                    }}
                    onClick={() => {
                      const emailRegex = new RegExp(
                        // eslint-disable-next-line no-control-regex
                        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
                      );

                      if (!emailRegex.test(email)) {
                        snackbar.error(
                          'Please enter a valid email address.'
                        );
                        return;
                      }

                      const apiUrl =
                        'https://api.airtable.com/v0/appAcsAUHyjZm3saP/Email';
                      const apiToken =
                        'patXWunKdmPHVzRrU.3be4727f6d74079742555d08e244190929bfd2efd91708d654f8a4a5db4fa0c8';

                      const headers = {
                        Authorization: `Bearer ${apiToken}`,
                        'Content-Type': 'application/json'
                      };

                      const data = {
                        records: [
                          {
                            fields: {
                              Name: '',
                              Email: email,
                              'Product Roadmap': 'Subscribed'
                            }
                          }
                        ]
                      };

                      setIsLoading(true);
                      fetch(apiUrl, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })
                        .then(() => {
                          setIsLoading(false);
                        })
                        .then(() => {
                          snackbar.success(
                            'Subscribed! See you in the inbox.'
                          );
                        })
                        .catch(() => {
                          snackbar.error(
                            'Unable to subscribe to newsletter.'
                          );
                        });
                      /* TODO: subscribe */
                    }}
                  >
                    <div className="flex h-10 w-10 items-center justify-center">
                      <ArrowRight
                        style={{
                          color: palette.primary.main,
                          rotate: isHovering ? '-30deg' : '0deg',
                          transformOrigin: 'center',
                          transition: 'all 0.2s ease-in-out'
                        }}
                      />
                    </div>
                  </IconButton>
                )
              }}
            />
          </div>

          <div className="mr-2 flex flex-grow flex-col pl-7">
            <p
              className="inline"
              style={{
                color: palette.text.primaryAlt2,
                ...typography.caption
              }}
            >
              By subscribing, you agree to our{' '}
            </p>
            <div className="flex">
              <p
                className="inline"
                style={{ ...typography.body2 }}
                color={palette.text.primaryAlt2}
              >
                <Link
                  variant="caption"
                  href={ROUTES.PRIVACY_POLICY}
                  color={palette.text.primaryAlt2}
                >
                  Privacy&nbsp;Policy
                </Link>
              </p>
              <p
                className="inline"
                style={{ ...typography.body2 }}
                color={palette.text.primaryAlt2}
              >
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function Footer() {
  const { isOnlyDesktop, isOnlyTablet, isOnlyMobile } = useDevice();
  const router = useRouter();
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);

  return (
    <div className="relative flex w-full flex-col items-start p-4">
      {isOnlyMobile && (
        <>
          <div
            className="pb-6"
            onClick={() => router.push(ROUTES.ROOT)}
          >
            <Logo />
          </div>
          <div className="w-full pt-8">
            <div className="flex w-full">
              {FOOTER_SECTIONS.map((item, itemId) => (
                <div
                  className="mb-8 w-full"
                  key={getListItemKey(itemId)}
                >
                  <div className="flex w-full flex-col items-start">
                    <div className="mb-2">
                      <p style={{ ...typography.overline }}>
                        {item.name}
                      </p>
                    </div>
                    {item.items.map((subitem) => (
                      <div
                        className="relative mt-2"
                        key={getListItemKey(subitem.name)}
                      >
                        {subitem.comingSoon ? (
                          <div className="flex flex-nowrap items-center">
                            <p className="text-muted">
                              {subitem.name}
                            </p>
                            <p className="ml-sm text-primary">Soon</p>
                          </div>
                        ) : (
                          <Link
                            color={palette.text.primaryAlt2}
                            href={subitem.path}
                          >
                            {subitem.name}
                          </Link>
                        )}
                        {subitem?.state && (
                          <div className="absolute right-[-200px] top-px flex h-full w-[200px] items-center">
                            <Chip
                              className="ml-2"
                              label={subitem.state}
                              size="mini"
                              color={
                                buttonStateChipColorMap[
                                  subitem.state
                                ] as ChipColorType
                              }
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {/* <div className="w-1/2" /> */}
              <div className="w-full">
                <div className="mb-4">
                  <p style={{ ...typography.overline }}>Newsletter</p>
                </div>
                <div>
                  <SubscribeBox />
                </div>
              </div>
            </div>
          </div>
          <div
            className="mt-10 h-px w-full"
            style={{
              backgroundColor: palette.info.dark
            }}
          />
          <div className="mt-6 w-full pb-10">
            <div className="flex w-full flex-col items-baseline justify-between">
              <div>
                <div className="flex w-full flex-col">
                  {FOOTER_FOOTNOTES.map((item) => (
                    <div
                      className="mt-0"
                      key={getListItemKey(item.name)}
                    >
                      <Link href={item.path}>
                        <p
                          className="inline"
                          style={{ ...typography.body2 }}
                        >
                          {item.name}
                        </p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <div className="flex w-full flex-row">
                  <p
                    className="inline"
                    style={{ ...typography.body2 }}
                  >
                    ©{new Date().getFullYear()} EmitIQ, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isOnlyTablet && (
        <>
          <div
            className="py-6"
            onClick={() => router.push(ROUTES.ROOT)}
          >
            <Logo />
          </div>
          <div className="w-full pt-8">
            <div className="flex w-full">
              {FOOTER_SECTIONS.map((item, itemId) => (
                <div
                  className="mb-8 w-1/2"
                  key={getListItemKey(itemId)}
                >
                  <div className="flex w-full flex-col items-start">
                    <div className="mb-2">
                      <p style={{ ...typography.overline }}>
                        {item.name}
                      </p>
                    </div>
                    {item.items.map((subitem) => {
                      return (
                        <div
                          className="relative mt-2"
                          key={getListItemKey(subitem.name)}
                        >
                          {subitem.comingSoon ? (
                            <div className="flex flex-nowrap items-center">
                              <p className="text-muted">
                                {subitem.name}
                              </p>
                              <p className="ml-sm text-primary">
                                Soon
                              </p>
                            </div>
                          ) : (
                            <Link
                              color={palette.text.primaryAlt2}
                              href={subitem.path}
                            >
                              {subitem.name}
                            </Link>
                          )}
                          {subitem?.state && (
                            <div className="absolute right-[-200px] top-px flex h-full w-[200px] items-center">
                              <Chip
                                className="ml-2"
                                label={subitem.state}
                                size="mini"
                                color={
                                  buttonStateChipColorMap[
                                    subitem.state
                                  ] as ChipColorType
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
              {/* <div className="w-1/2" /> */}
              <div className="w-1/2">
                <div className="mb-4">
                  <p style={{ ...typography.overline }}>Newsletter</p>
                </div>
                <div>
                  <SubscribeBox />
                </div>
              </div>
            </div>
          </div>
          <div
            className="my-6 h-px w-full"
            style={{
              backgroundColor: palette.info.dark
            }}
          />
          <div className="w-full pb-10">
            <div className="flex w-full flex-row items-baseline justify-between">
              <div>
                <div className="flex w-full flex-row">
                  {FOOTER_FOOTNOTES.map((item) => (
                    <div
                      className="mr-6"
                      key={getListItemKey(item.name)}
                    >
                      <Link href={item.path}>
                        <p
                          className="inline"
                          style={{ ...typography.body2 }}
                        >
                          {item.name}
                        </p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <div className="flex w-full flex-row">
                  <p
                    className="inline"
                    style={{ ...typography.body2 }}
                  >
                    ©{new Date().getFullYear()} EmitIQ, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isOnlyDesktop && (
        <>
          <div
            className="py-6"
            onClick={() => router.push(ROUTES.ROOT)}
          >
            {/* <MonochromeLogo /> */}
            <Logo />
          </div>
          <div className="w-full pt-8">
            <div className="flex w-full flex-row justify-start">
              {FOOTER_SECTIONS.map((item, itemId) => (
                <div
                  className="mb-8 w-1/4"
                  key={getListItemKey(itemId)}
                >
                  <div className="flex w-full flex-col items-start">
                    <div className="mb-2">
                      <p style={{ ...typography.overline }}>
                        {item.name}
                      </p>
                    </div>
                    {item.items.map((subitem) => (
                      <div
                        className="relative mt-2 flex-row"
                        key={getListItemKey(subitem.name)}
                      >
                        {subitem.comingSoon ? (
                          <div className="flex flex-nowrap items-center">
                            <p className="text-muted">
                              {subitem.name}
                            </p>
                            <p className="ml-sm text-primary">Soon</p>
                          </div>
                        ) : (
                          <Link
                            color={palette.text.primaryAlt2}
                            href={subitem.path}
                          >
                            {subitem.name}
                          </Link>
                        )}
                        {subitem?.state && (
                          <div className="absolute right-[-200px] top-px flex h-full w-[200px] items-center">
                            <Chip
                              className="ml-2"
                              label={subitem.state}
                              size="mini"
                              color={
                                buttonStateChipColorMap[
                                  subitem.state
                                ] as ChipColorType
                              }
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div style={{ width: '35%' }}>
                <div className="mb-4">
                  <p style={{ ...typography.overline }}>Newsletter</p>
                </div>
                <div className="pr-4">
                  <SubscribeBox />
                </div>
              </div>
            </div>
          </div>
          <div
            className="mb-8 mt-8 h-px w-full"
            style={{
              backgroundColor: palette.info.dark
            }}
          />
          <div className="w-full pb-6">
            <div className="flex w-full flex-row items-baseline justify-between pb-2">
              <div>
                <div className="flex w-full flex-row">
                  {FOOTER_FOOTNOTES.map((item) => (
                    <div
                      className="mr-6"
                      key={getListItemKey(item.name)}
                    >
                      <Link href={item.path}>
                        <p
                          className="inline"
                          style={{ ...typography.body2 }}
                        >
                          {item.name}
                        </p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="flex w-full flex-row">
                  <p
                    className="inline"
                    style={{ ...typography.body2 }}
                  >
                    ©{new Date().getFullYear()} EmitIQ, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  DeleteReportingPeriodInput,
  DeleteReportingPeriodPayload,
  ReportingPeriodObject
} from 'src/__apolloGenerated__/graphql';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import useMeasureStore from 'src/hooks/store/useMeasureStore';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import CreateReportingPeriodDialog from './CreateReportingPeriodDialog';

export default function ReportingPeriodSelector({
  activeReportingPeriodId,
  activeEntityId = null,
  reportingPeriods,
  disabled = false,
  disableClear = true,
  loading,
  required = false,
  refetchActiveEntity
}: {
  activeReportingPeriodId: string;
  activeEntityId: string;
  reportingPeriods: ReportingPeriodObject[];
  disabled?: boolean;
  loading?: boolean;
  disableClear?: boolean;
  required?: boolean;
  refetchActiveEntity: () => void;
  // Some components will need more than just the branch identifier, so optionally we will pass a setter for the whole branch object
}) {
  const {
    setSelectedRangeType,
    setSelectedStartDate,
    setSelectedEndDate
  } = useMeasureStore((store) => {
    return {
      setSelectedRangeType: store.setSelectedRangeType,
      setSelectedStartDate: store.setSelectedStartDate,
      setSelectedEndDate: store.setSelectedEndDate
    };
  });

  const {
    setActiveReportingPeriodId,
    setActiveReportingPeriodStartDate,
    setActiveReportingPeriodEndDate
  } = useSettingsStore();

  const [
    createReportingPeriodDialogOpen,
    setCreateReportingPeriodDialogOpen
  ] = useState(false);

  const [
    manageReportingPeriodDialogOpen,
    setManageReportingPeriodDialogOpen
  ] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState({
    key: '',
    label: ''
  });

  const { mutate: deleteReportingPeriod } = useBackendMutation<
    { input: DeleteReportingPeriodInput },
    DeleteReportingPeriodPayload
  >({
    mutation: gql`
      mutation ReportingPeriodSelectorDeleteReportingPeriod(
        $input: DeleteReportingPeriodInput!
      ) {
        deleteReportingPeriod(input: $input) {
          ok
          entity {
            reportingPeriods {
              identifier
              startDate
              endDate
            }
          }
        }
      }
    `,
    callbacks: {
      onSuccess: () => {
        if (selectedPeriod.key === activeReportingPeriodId) {
          setActiveReportingPeriodId(reportingPeriods[0].identifier);
          setActiveReportingPeriodStartDate(
            new Date(reportingPeriods[0].startDate)
          );
          setActiveReportingPeriodEndDate(
            new Date(reportingPeriods[0].endDate)
          );
          setSelectedRangeType('Custom');
          setSelectedStartDate(
            new Date(reportingPeriods[0].startDate)
          );
          setSelectedEndDate(new Date(reportingPeriods[0].endDate));
        }
        refetchActiveEntity();
      }
    },
    errorHandlers: {}
  });

  return (
    <>
      <CreateReportingPeriodDialog
        open={createReportingPeriodDialogOpen}
        setOpen={setCreateReportingPeriodDialogOpen}
        activeEntityId={activeEntityId}
        refetchData={refetchActiveEntity}
        onCancel={() => {
          setCreateReportingPeriodDialogOpen(false);
          setManageReportingPeriodDialogOpen(true);
        }}
      />
      <Dialog
        open={manageReportingPeriodDialogOpen}
        onOpenChange={setManageReportingPeriodDialogOpen}
      >
        <DialogContent>
          <div className="flex flex-col flex-nowrap items-center">
            <div className="pt-md">
              <h5>Reporting Period</h5>
            </div>

            <p className="max-w-paragraph text-center text-muted">
              {selectedPeriod.label}
            </p>
            <div className="mb-md ml-md mt-md">
              {/* <LearnButton /> */}
            </div>
            <div className="flex w-full flex-col items-center justify-between">
              <Button
                className="m-sm block w-[50%]"
                onClick={() => {
                  const reportingPeriod = reportingPeriods.find(
                    (x) => x.identifier === selectedPeriod.key
                  );
                  setSelectedRangeType('Custom');
                  setSelectedStartDate(
                    new Date(reportingPeriod.startDate)
                  );
                  setSelectedEndDate(
                    new Date(reportingPeriod.endDate)
                  );
                  setActiveReportingPeriodId(
                    reportingPeriod.identifier
                  );
                  setActiveReportingPeriodStartDate(
                    reportingPeriod.startDate
                  );
                  setActiveReportingPeriodEndDate(
                    reportingPeriod.endDate
                  );
                  setManageReportingPeriodDialogOpen(false);
                }}
              >
                Use
              </Button>
              <Button
                className="m-sm block w-[50%]"
                variant="destructive"
                onClick={() => {
                  const reportingPeriod = reportingPeriods.find(
                    (x) => x.identifier === selectedPeriod.key
                  );
                  deleteReportingPeriod({
                    variables: {
                      input: {
                        reportingPeriodIdentifier:
                          reportingPeriod.identifier
                      }
                    }
                  });
                  setManageReportingPeriodDialogOpen(false);
                }}
              >
                Delete
              </Button>
              <p className="py-sm font-bold">OR</p>
              <Button
                className="m-sm block w-[50%]"
                variant="outline"
                onClick={() => {
                  setManageReportingPeriodDialogOpen(false);
                  setCreateReportingPeriodDialogOpen(true);
                }}
              >
                Add new
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SingleSelect
        value={activeReportingPeriodId}
        required={required}
        disabled={disabled}
        setValue={() => null}
        loading={loading}
        placeholder="Select period..."
        commandEmptyText="No reporting periods found."
        options={reportingPeriods?.map((period) => ({
          key: period.identifier,
          label:
            dayjs(period.startDate).format('MM.DD.YY') +
            ' - ' +
            dayjs(period.endDate).format('MM.DD.YY'),
          startDate: new Date(period.startDate),
          endDate: new Date(period.endDate)
        }))}
        prefixText={'Period:'}
        renderOption={(periodOption) => (
          <div
            className="flex w-full items-center"
            onClick={() => {
              setSelectedPeriod(periodOption);
              setManageReportingPeriodDialogOpen(true);
            }}
          >
            <p className="body2">{periodOption.label}</p>
          </div>
        )}
        disableClear={disableClear}
      />
    </>
  );
}

import { cn } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import { Columns, ExternalLink, Lock, LogOut, X } from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  EntityFragmentFragment,
  ReconcileSubscriptionStatusInput,
  ReconcileSubscriptionStatusPayload,
  ReportingPeriodObject,
  SetActiveEntityInput,
  SetActiveEntityPayload,
  SidebarEntityQueryQuery,
  SurveyTemplatesEnum,
  ToReviewAggregateMeasurementsFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import ReportingPeriodSelector from 'src/components/carbon/molecules/ReportingPeriodSelector';
import Icon from 'src/components/core/atoms/Icon';
import Skeleton from 'src/components/core/atoms/Skeleton';
import OnboardingSelector from 'src/components/core/molecules/Onboarding/OnboardingSelector';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import { Button } from 'src/components/shad-base/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';
import { ROUTES, SUBSCRIPTION_PLANS } from 'src/config';
import useAppStore from 'src/hooks/store/useAppStore';
import useAuthPersistStore from 'src/hooks/store/useAuthPersistStore';
import useFootprintStore, {
  FootprintStoreType,
  HeadType
} from 'src/hooks/store/useFootprintStore';
import useMeasureStore, {
  MeasureStoreType,
  UNLOCKED_COLUMN_KEY_SCHEMA_MATCH
} from 'src/hooks/store/useMeasureStore';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import useSubscriptionStore, {
  SubscriptionStoreType
} from 'src/hooks/store/useSubcriptionStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { SET_ACTIVE_ENTITY } from 'src/hooks/useBackendMutation/mutations';
import usePaywall from 'src/hooks/usePaywall';
import CollisionSurveyNotification from 'src/layouts/Layout/SidebarNav/CollisionSurveyNotification';
import ReviewMeasurementsPopper from 'src/layouts/Layout/SidebarNav/ReviewMeasurementsPopper';
import { getListItemKey } from 'src/utils/format';

type NavItemType = {
  key: string;
  name: string;
  onClick?: () => void;
  isSelected?: boolean;
  chip?: string;
  subItems?: {
    name: string;
    onClick: () => void;
    isSelected: boolean;
    locked?: boolean;
    icon?: React.ReactNode;
  }[];
  icon?: React.ReactNode;
  accordionOpen?: string;
  external?: boolean;
  locked?: boolean;
};

export type ToReviewMeasurementType =
  ToReviewAggregateMeasurementsFragmentFragment['toReviewAggregateMeasurements']['edges'][0]['node'];

type ActiveEntityType = EntityFragmentFragment &
  SidebarEntityQueryQuery['activeEntity']['data']['tree'];

export default function SidebarNav() {
  const router = useRouter();
  const { signout } = useAuthPersistStore();
  const { setSidebarMinimized, sidebarMinimized } = useAppStore(
    (store) => ({
      setSidebarMinimized: store.setSidebarMinimized,
      sidebarMinimized: store.sidebarMinimized
    })
  );

  const { activeSubscriptionTier }: SubscriptionStoreType =
    useSubscriptionStore();

  const {
    isAuthorized: authorizedForGroupsAndPermissions,
    requiredPlan: groupsRequiredPlan,
    subtitleText: groupsSubtitleText
  } = usePaywall('groups-and-permissions');
  const {
    isAuthorized: authorizedForMembers,
    requiredPlan: membersRequiredPlan,
    subtitleText: membersSubtitleText
  } = usePaywall('team');

  const {
    setRootNodeId,
    selectedStartDate,
    selectedEndDate,
    selectedFilters
  } = useMeasureStore((store: MeasureStoreType) => ({
    setRootNodeId: store.setRootNodeId,
    selectedStartDate: store.selectedStartDate,
    selectedEndDate: store.selectedEndDate,
    selectedFilters: store.selectedFilters
  }));

  const {
    setHeads,
    selectedTree,
    setSelectedTree,
    activeEntityName,
    setActiveEntityName,
    toReviewMeasurementsTotal,
    setToReviewMeasurementsTotal
  } = useFootprintStore((store: FootprintStoreType) => ({
    activeEntityName: store.activeEntityName,
    setActiveEntityName: store.setActiveEntityName,
    toReviewMeasurementsTotal: store.toReviewMeasurementsTotal,
    setToReviewMeasurementsTotal: store.setToReviewMeasurementsTotal,
    setHeads: store.setHeads,
    selectedTree: store.selectedTree,
    setSelectedTree: store.setSelectedTree
  }));

  const {
    setPaywallDialogOpen,
    setRequiredPlan,
    setSubtitleText
  }: SubscriptionStoreType = useSubscriptionStore();

  const { sideNavOpenState, setSideNavOpenState } =
    useSettingsStore();

  const {
    data,
    loading: loadingEntity,
    refetch: refetchActiveEntity,
    previousData
  } = useQuery<SidebarEntityQueryQuery>(
    gql`
      query SidebarEntityQuery(
        $startDate: DateTime
        $endDate: DateTime
      ) {
        activeEntity {
          errors {
            ...ErrorsFragment
          }
          ok
          data {
            ...EntityFragment
            tree {
              head {
                ...ToReviewAggregateMeasurementsFragment
              }
              branches {
                name
                identifier
                path
              }
            }
          }
        }
        entities {
          errors {
            ...ErrorsFragment
          }
          data {
            identifier
            name
            inPublicDirectory
            tree {
              identifier
              head {
                identifier
                name
              }
            }
          }
        }
        user {
          errors {
            ...ErrorsFragment
          }
          data {
            isSuperuser
            identifier
            activeAccount {
              user {
                identifier
              }
              surveySubmissions {
                identifier
                isSubmitted
                survey {
                  identifier
                  template
                }
              }
              measureViewColumns
            }
          }
        }
        collisionSurveyInvite {
          errors {
            ...ErrorsFragment
          }
          data {
            identifier
          }
        }
      }
    `,
    {
      variables: {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        states: selectedFilters
      },
      // If one query returns an error, don't prevent the others from returning data
      errorPolicy: 'all'
    }
  );

  const user = data?.user?.data;
  const entities = data?.entities?.data;
  const activeEntity = data?.activeEntity?.data as ActiveEntityType;

  const {
    activeReportingPeriodId,
    setActiveReportingPeriodId,
    setActiveReportingPeriodStartDate,
    setActiveReportingPeriodEndDate
  } = useSettingsStore();

  const previousDataEntity = previousData?.activeEntity
    ?.data as ActiveEntityType;

  const collisionSurveySubmission =
    user?.activeAccount?.surveySubmissions?.find((submission) => {
      return (
        submission?.survey?.template === SurveyTemplatesEnum.Collision
      );
    });

  const collisionSurveyInvite = data?.collisionSurveyInvite?.data;

  const heads =
    activeEntity?.tree?.heads?.length > 0
      ? activeEntity?.tree?.heads
      : null;
  const head = activeEntity?.tree
    ?.head as ToReviewAggregateMeasurementsFragmentFragment;
  const toReviewMeasurements =
    head?.toReviewAggregateMeasurements?.edges.map(
      (edge) => edge.node
    ) as ToReviewMeasurementType[];

  const { mutate: setActiveEntity } = useBackendMutation<
    { input: SetActiveEntityInput },
    SetActiveEntityPayload
  >({
    mutation: SET_ACTIVE_ENTITY,
    callbacks: {
      onSuccess: (data) => {
        useMeasureStore.getState().setActiveNode({
          nodeId: null,
          type: null,
          path: null
        });
        useFootprintStore
          .getState()
          .setHeads(data?.entity?.tree?.heads as HeadType[]);

        if (router.pathname !== ROUTES.INVENTORY.MEASURE) {
          router.push(ROUTES.INVENTORY.MEASURE);
        }
        refetchActiveEntity();
      }
    }
  });
  const { mutate: reconcileSubscriptionStatus } = useBackendMutation<
    { input: ReconcileSubscriptionStatusInput },
    ReconcileSubscriptionStatusPayload
  >({
    mutation: gql`
      mutation ReconcileSubscriptionStatus(
        $input: ReconcileSubscriptionStatusInput!
      ) {
        reconcileSubscriptionStatus(input: $input) {
          ok
          user {
            ...UserFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `
  });
  let isViewingOtherAccount = false;

  //if the userId attached to the active account is not the same as the resolved userId,
  //and the current user is a superuser, then they are viewing another user's account
  if (
    user?.activeAccount?.user?.identifier !== user?.identifier &&
    user?.isSuperuser
  ) {
    isViewingOtherAccount = true;
  }

  // // On first load select first tree
  useEffect(() => {
    if (heads) {
      const head = heads[0];
      setRootNodeId(heads[0]?.identifier);
      if (!selectedTree?.identifier) {
        setSelectedTree({
          path: head?.path,
          identifier: head?.identifier,
          name: head?.name,
          userPermissions: head?.userPermissions
        });
        setHeads(
          heads.map((head) => {
            return {
              path: head?.path,
              identifier: head?.identifier,
              name: head?.name,
              userPermissions: head?.userPermissions
            };
          })
        );
      }
    }
    if (activeEntity?.billing?.subscriptionReconciliationRequired) {
      reconcileSubscriptionStatus({
        variables: {
          input: {
            harmonize: true
          }
        }
      });
    }
    if (activeEntity) {
      setActiveEntityName(activeEntity?.name);
      setToReviewMeasurementsTotal(
        head?.toReviewAggregateMeasurements?.totalCount
      );
      if (
        activeEntity.reportingRequirementsCompleted &&
        !activeReportingPeriodId &&
        activeEntity.reportingPeriods.length > 0
      ) {
        setActiveReportingPeriodId(
          activeEntity?.reportingPeriods[0].identifier
        );
        setActiveReportingPeriodStartDate(
          new Date(activeEntity?.reportingPeriods[0].startDate)
        );
        setActiveReportingPeriodEndDate(
          new Date(activeEntity?.reportingPeriods[0].endDate)
        );
      }
    }
  }, [heads, activeEntity]);

  useEffect(() => {
    useMeasureStore
      .getState()
      .setUnlockedColumnKeys(
        user?.activeAccount?.measureViewColumns?.map(
          (key: string) => UNLOCKED_COLUMN_KEY_SCHEMA_MATCH[key]
        )
      );
  }, [user]);

  const getMyFootprintNavItem = (page: string) => {
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/inventory/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/inventory/' + page);
        }
      }
    };
  };

  const getMyFootprintNavItemOrganization = (page: string) => {
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/organization/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/organization/' + page);
        }
      }
    };
  };

  const getSettingsNavItem = (page: string) => {
    const isAuthorized = {
      roles: authorizedForGroupsAndPermissions,
      team: authorizedForMembers
    };
    return {
      name: page === 'roles' ? 'Roles' : _.startCase(page),
      isSelected: router.pathname.endsWith(page),
      onClick: () => {
        // If not authorized, open subscribe dialog
        if (!isAuthorized[page] && ['roles', 'team'].includes(page)) {
          setPaywallDialogOpen(true);
          setRequiredPlan(
            page === 'roles'
              ? groupsRequiredPlan
              : membersRequiredPlan
          );
          setSubtitleText(
            page === 'roles'
              ? groupsSubtitleText
              : membersSubtitleText
          );
          return;
        }
        if (!router.pathname.endsWith(page)) {
          router.push(ROUTES.SETTINGS[page.toUpperCase()]);
        }
      },
      locked:
        ['roles', 'team'].includes(page) &&
        !isAuthorized[page] &&
        !loadingEntity
    };
  };

  const navItems: NavItemType[] = [
    {
      key: 'dashboard',
      name: 'Dashboard',
      isSelected: router.pathname === ROUTES.DASHBOARD,
      onClick: () => {
        if (router.pathname !== ROUTES.DASHBOARD) {
          router.push(ROUTES.DASHBOARD);
        }
      }
    },
    {
      key: 'organization',
      name: 'Organization',
      subItems: [
        getMyFootprintNavItemOrganization('locations'),
        getMyFootprintNavItemOrganization('vehicles'),
        getMyFootprintNavItemOrganization('equipment')
      ],
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/organization') &&
        !sideNavOpenState['organization'],
      accordionOpen: router.pathname.startsWith('/organization')
        ? 'organization'
        : null
    },
    {
      key: 'inventory',
      name: 'Carbon Inventory',
      subItems: [
        getMyFootprintNavItem('measure'),
        getMyFootprintNavItem('reduce'),
        getMyFootprintNavItem('share')
        // getMyFootprintNavItem('certify')
      ],
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/inventory') &&
        !sideNavOpenState['inventory'],
      accordionOpen: router.pathname.startsWith('/inventory')
        ? 'inventory'
        : null
    },
    {
      key: 'directory',
      name: 'Company Directory',
      onClick: () => {
        router.push(ROUTES.DIRECTORY);
      },
      external: true
    },
    // {
    //   key: 'open-impact-standard',
    //   name: 'Carbon Removal',
    //   chip: 'Alpha',
    //   onClick: () => {
    //     router.push(ROUTES.OPEN_IMPACT_STANDARD.LIST);
    //   },
    //   isSelected: router.pathname.includes(
    //     ROUTES.OPEN_IMPACT_STANDARD.LIST
    //   )
    // },
    {
      key: 'settings',
      name: 'Settings',
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/settings/') &&
        !sideNavOpenState['settings'],
      subItems: [
        getSettingsNavItem('overview'),
        getSettingsNavItem('billing'),
        getSettingsNavItem('team'),
        getSettingsNavItem('roles')
      ]
    }
  ];

  const itemClassName = (isSelected) => {
    return cn(
      'flex items-center p-sm h-[35px] rounded-lg cursor-pointer hover:bg-primary/10 transition-all ',
      isSelected ? 'bg-primary/10' : ''
    );
  };

  // function getTooltipContent(name) {
  //   if (name == 'Dashboard') {
  //     return 'This is your command center for managing your sustainability journey at a high level.';
  //   }
  //   if (name == 'Carbon Inventory') {
  //     return 'These tabs let you control your footprint. Measure your impact, Reduce your emissions, Share your progress, and offset unnavoidable CO2.';
  //   }
  //   if (name == 'Settings') {
  //     return 'Manage your plan, team and integrations using these tabs.';
  //   }
  // }

  // Collision Survey Notification
  const today = new Date();
  const contestDeadline = new Date('2024-06-26');
  const resultsReleased = new Date('2024-07-03');

  const isCollisionSurveyComplete =
    collisionSurveySubmission?.isSubmitted;
  const completeYourData =
    isCollisionSurveyComplete && today < contestDeadline;
  const waitForResults =
    isCollisionSurveyComplete &&
    today > contestDeadline &&
    today < resultsReleased;

  const tree =
    activeEntity?.tree as SidebarEntityQueryQuery['activeEntity']['data']['tree'];
  const purchasesBranch = tree?.branches?.find((branch) => {
    return branch?.name === 'Purchases';
  });

  const reportingPeriods =
    activeEntity?.reportingPeriods as ReportingPeriodObject[];

  return sidebarMinimized ? (
    <div
      className={
        'h-full w-left-min-sidebar border-r ' +
        (isViewingOtherAccount ? 'bg-warning/50' : '')
      }
    >
      <Button
        variant="outline"
        size="icon"
        className="mt-3"
        onClick={() => setSidebarMinimized(false)}
      >
        <Columns className="scale-75" />
      </Button>
    </div>
  ) : (
    <div className="relative flex h-full w-left-sidebar min-w-left-sidebar max-w-left-sidebar flex-col flex-nowrap border-r bg-background">
      <div className="h-full max-h-screen w-full overflow-y-auto p-md">
        {/* Entity Name */}
        {isViewingOtherAccount && (
          <div className="absolute left-0 top-0 flex h-[30px] w-full items-center justify-center bg-warning/50">
            <p className="body2 font-bold text-warning-foreground">
              Superuser View
            </p>
          </div>
        )}
        <div
          className={
            'mb-md w-full ' + (isViewingOtherAccount ? 'mt-lg' : '')
          }
        >
          <div className="flex max-w-full flex-nowrap items-center justify-between ">
            <div className="flex max-w-full flex-nowrap items-center">
              <div
                className="cursor-pointer"
                onClick={() => router.push(ROUTES.DASHBOARD)}
              >
                <Icon size="xs" />
              </div>
              {!activeEntityName && loadingEntity && !previousData ? (
                <div className="ml-sm">
                  <Skeleton height={20} width={150} />
                </div>
              ) : entities?.length > 1 ? (
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <div
                      className={
                        'ml-sm w-full max-w-[150px] ' +
                        itemClassName(false)
                      }
                    >
                      <p className={'min-w-0 truncate '}>
                        {activeEntityName}
                      </p>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="z-[600]">
                    <DropdownMenuLabel>
                      Switch Organization
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {entities.map((entity, index) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={getListItemKey(index)}
                          checked={
                            activeEntity?.identifier ===
                            entity.identifier
                          }
                          onCheckedChange={() => {
                            setActiveEntity({
                              variables: {
                                input: {
                                  entityIdentifier: entity.identifier
                                }
                              }
                            });
                          }}
                        >
                          <div className="flex flex-nowrap items-center">
                            <p>{entity.name}</p>
                          </div>
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <div className={'ml-sm w-full max-w-[150px] '}>
                  <p className={'min-w-0 truncate '}>
                    {loadingEntity && previousDataEntity
                      ? previousDataEntity?.name
                      : activeEntityName}
                  </p>
                </div>
              )}
            </div>
            <div>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setSidebarMinimized(true)}
              >
                <X size={15} />
              </Button>
            </div>
          </div>
        </div>
        {loadingEntity ? (
          <div className="ml-sm">
            <Skeleton height={20} width={150} />
          </div>
        ) : (
          <ReportingPeriodSelector
            activeReportingPeriodId={activeReportingPeriodId}
            reportingPeriods={reportingPeriods}
            activeEntityId={activeEntity?.identifier}
            refetchActiveEntity={refetchActiveEntity}
          />
        )}

        {/* Collision Survey Notification */}
        {collisionSurveySubmission &&
        !loadingEntity &&
        (waitForResults ||
          completeYourData ||
          !isCollisionSurveyComplete) ? (
          <CollisionSurveyNotification
            isCollisionSurveyComplete={isCollisionSurveyComplete}
            waitForResults={waitForResults}
            completeYourData={completeYourData}
            surveyInviteIdentifier={collisionSurveyInvite?.identifier}
            purchasesBranch={{
              path: purchasesBranch?.path,
              identifier: purchasesBranch?.identifier
            }}
          />
        ) : null}
        {/* Notifications */}
        {toReviewMeasurementsTotal ? (
          <div className={' w-full ' + itemClassName(false)}>
            <ReviewMeasurementsPopper
              toReviewMeasurements={toReviewMeasurements}
            />
          </div>
        ) : null}
        <div className="mb-md mt-sm">
          <OnboardingSelector />
        </div>

        {/* Navigation Items */}
        {navItems.map((item, idx) => {
          return (
            <div
              key={getListItemKey(idx)}
              className={idx !== 0 ? 'mt-sm' : undefined}
              onClick={() => item.onClick && item.onClick()}
            >
              {item.subItems ? (
                <Accordion
                  type="multiple"
                  value={Object.keys(sideNavOpenState).reduce(
                    (acc, key) => {
                      if (sideNavOpenState[key]) {
                        acc.push(key);
                      }
                      return acc;
                    },
                    []
                  )}
                  onValueChange={(value) => {
                    setSideNavOpenState({
                      organization: value.includes('organization'),
                      inventory: value.includes('inventory'),
                      settings: value.includes('settings')
                    });
                  }}
                >
                  <AccordionItem value={item.key}>
                    <AccordionTrigger
                      className={
                        'w-full ' + itemClassName(item.isSelected)
                      }
                    >
                      <p>{item.name}</p>
                    </AccordionTrigger>
                    <AccordionContent className="mt-sm p-sm pb-0">
                      {item.subItems.map((subItem, subIdx) => {
                        return (
                          <div
                            key={getListItemKey(subIdx)}
                            className={
                              (subIdx !== 0 ? 'mt-sm ' : '') +
                              itemClassName(subItem.isSelected)
                            }
                            onClick={() => subItem.onClick()}
                          >
                            <div className="flex w-full flex-nowrap items-center justify-between">
                              <p className="text-nowrap">
                                {subItem.name}
                              </p>
                              {subItem.locked && (
                                <Lock className="mr-sm h-icon min-h-icon w-icon min-w-icon" />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ) : (
                <div
                  className={
                    'flex flex-nowrap items-center justify-between ' +
                    itemClassName(item.isSelected)
                  }
                >
                  <p>{item.name}</p>
                  <div className="flex items-center">
                    {item.chip && (
                      <div className="ml-sm flex h-[25px] items-center rounded-full bg-primary/15 px-md">
                        <p className="body2 text-primary">
                          {item.chip}
                        </p>
                      </div>
                    )}
                    {item.locked && (
                      <Lock className="ml-sm mr-sm h-icon w-icon" />
                    )}
                    {item.external && (
                      <ExternalLink className="scale-75 text-muted" />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {/* Extra scroll space  */}
        <div className="py-lg" />
      </div>

      {/* Plan Chip */}
      <div className="absolute bottom-4 left-4 flex items-center justify-center rounded-full">
        <Button
          variant="outline"
          size="icon"
          className="bg-card"
          onClick={() => signout()}
        >
          <LogOut className="h-4 w-4" />
        </Button>
      </div>
      <div className="absolute bottom-4 right-4">
        {loadingEntity ? (
          <div className="ml-sm">
            <Skeleton
              height={30}
              width={80}
              sx={{ borderRadius: 5 }}
            />
          </div>
        ) : (
          activeEntity && (
            <div
              className="p-s ml-sm flex h-[30px] cursor-pointer items-center rounded-full bg-card px-md hover:bg-accent"
              onClick={() => {
                router.push(ROUTES.SETTINGS.BILLING);
              }}
            >
              <p className="body2">
                {SUBSCRIPTION_PLANS[activeSubscriptionTier]?.name}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
}

import { ChevronDown } from 'lucide-react';
import SelectPopper, {
  PlacementType
} from 'src/components/core/molecules/SelectPopper';
import useOnboarding, {
  OnboardingType
} from 'src/hooks/store/useOnboardingStore';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';

import getIntercom, { INTERCOM_ARTICLES } from 'src/utils/intercom';

export default function OnboardingSectionSelector({
  placement = 'bottom-start',
  isOpenState = undefined,
  disabled = false,
  renderCurrentSection = () => null
}: {
  placement?: PlacementType;
  isOpenState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  disabled?: boolean;
  renderCurrentSection?: (section: string) => JSX.Element;
}) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  if (renderCurrentSection === null) {
    renderCurrentSection = (branch) => {
      return (
        <>
          <>
            <div
              className="flex w-full cursor-pointer flex-nowrap items-center justify-between pb-2"
              style={{
                borderBottom: 1,
                borderColor: palette.divider
              }}
            >
              <div>
                <p
                  style={{ ...typography.subtitle1 }}
                  className="whitespace-nowrap"
                >
                  {branch}
                </p>
              </div>
              <div>
                <ChevronDown />
              </div>
            </div>
          </>
        </>
      );
    };
  }

  const { active, currentSection } = useOnboarding(
    (store: OnboardingType) => ({
      active: store.active,
      currentSection: store.currentSection
    })
  );

  const sectionOptions = {
    'Getting Started': 'Getting Started'
    // 'Create a Measurement': 'Create a Measurement'
  };

  // const router = useRouter();
  const intercom = getIntercom();
  return (
    <>
      <SelectPopper
        isOpenState={isOpenState}
        options={sectionOptions}
        disabled={disabled}
        placement={placement as PlacementType}
        onChange={() => {
          intercom.showArticle(INTERCOM_ARTICLES['Getting Started']);
        }}
        value={active ? currentSection : null}
        renderAnchorEl={() => {
          return renderCurrentSection(currentSection);
        }}
        renderOption={(option) => {
          return (
            <div className="flex w-full flex-nowrap items-center px-0">
              <div>
                <p
                  style={{
                    ...typography.caption,
                    color:
                      active && currentSection === option
                        ? palette.primary.main
                        : palette.text.primary
                  }}
                  className="whitespace-nowrap"
                >
                  {option}
                </p>
              </div>
            </div>
          );
        }}
        initialSelectedOptions={{}}
        loading={false}
        minWidth={600}
      />
    </>
  );
}

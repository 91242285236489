import { Drawer, Dialog as MDialog, PaperProps } from '@mui/material';
import { X } from 'lucide-react';
import {
  BranchSelectorTreeQueryQuery,
  GoalFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import MultiSwitch from 'src/components/carbon/atoms/MultiSwitch';
import BranchSelector from 'src/components/carbon/molecules/BranchSelector';
import GoalSelector from 'src/components/core/molecules/GoalSelector';
import { Button } from 'src/components/shad-base/button';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import useDevice from 'src/hooks/useDevice';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';
import { create } from 'zustand';
import IconButton from '../atoms/IconButton';

//---------------------------------------------------------------------------

type DisableEscKeyStoreType = {
  disableEscKey: boolean;
  setDisableEscKey: (disableEscKey: boolean) => void;
};
export const useDisableEscKeyStore = create<DisableEscKeyStoreType>(
  (set) => ({
    disableEscKey: false,
    setDisableEscKey: (disableEscKey) => {
      set({ disableEscKey });
    }
  })
);

export default function Dialog<T>({
  open,
  onClose,
  title,
  renderTitle,
  renderTitleOverline,
  toggles = null,
  activeBranchIdentifierState = null,
  activeGoalState = null,
  // initialBranch=null,
  disableActiveBranch = false,
  disableActiveGoal = false,
  disableToggles = false,
  children,
  maxWidth = 'md',
  fullScreen = false,
  paperSx,
  minWidth = 400,
  altButtons = [],
  buttons = [],
  invertButtonColors = false,
  getIsValidBranch = null,
  // Some components will need more than just the branch identifier, so optionally we will pass a setter for the whole branch object
  setActiveBranch
}: {
  open: boolean;
  onClose: (e) => void;
  title?: string;
  renderTitle?: () => React.ReactNode;
  renderTitleOverline?: () => React.ReactNode;
  toggles?: {
    modes: T[];
    selectedMode: T;
    setSelectedMode: React.Dispatch<React.SetStateAction<T>>;
  };
  activeBranchIdentifierState?: [
    string,
    React.Dispatch<React.SetStateAction<string>>
  ];
  activeGoalState?: [
    GoalFragmentFragment | any,
    React.Dispatch<React.SetStateAction<GoalFragmentFragment | any>>
  ];
  initialBranch?: T;
  disableToggles?: boolean;
  disableActiveBranch?: boolean;
  disableActiveGoal?: boolean;
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  paperSx?: PaperProps['sx'];
  fullScreen?: boolean;
  minWidth?: number;
  altButtons?: {
    key: string;
    label: string;
    disabled?: boolean;
    onClick: () => void;
    variation: 'continue' | 'cancel' | 'back' | 'alternative';
    invertColors?: boolean;
  }[];
  buttons?: {
    key: string;
    label: string;
    disabled?: boolean;
    onClick: () => void;
    variation: 'continue' | 'cancel' | 'back' | 'alternative';
    invertColors?: boolean;
    loading?: boolean;
  }[];
  invertButtonColors?: boolean;
  getIsValidBranch?: (
    branch: BranchSelectorTreeQueryQuery['tree']['data']['branches'][0]
  ) => boolean;
  setActiveBranch?: (
    branch: BranchSelectorTreeQueryQuery['tree']['data']['branches'][0]
  ) => void;
}) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  const { isMobile } = useDevice();

  const { disableEscKey } = useDisableEscKeyStore();

  const getButtonColor = (button) => {
    switch (button.variation) {
      case 'continue':
        return invertButtonColors ? 'error' : 'primary';
      case 'cancel':
        return invertButtonColors ? 'primary' : 'error';
      case 'back':
        return 'inherit';
    }
  };
  const getButtonVariation = (button) => {
    switch (button.variation) {
      case 'continue':
        return 'default';
      case 'alternative':
        return 'outline';
      case 'cancel':
        return 'outline';
      case 'back':
        return 'outline';
    }
  };

  let buttonsToRender = buttons;
  const backButton = buttons.find(
    (button) => button.variation === 'back'
  );
  if (backButton) {
    buttonsToRender = buttons.filter(
      (button) => button.variation !== 'back'
    );
  }

  let activeBranchIdentifier = null;
  let setActiveBranchIdentifier = null;
  if (activeBranchIdentifierState) {
    [activeBranchIdentifier, setActiveBranchIdentifier] =
      activeBranchIdentifierState;
  }

  let activeGoal = null;
  let setActiveGoal = null;
  if (activeGoalState) {
    [activeGoal, setActiveGoal] = activeGoalState;
  }

  return isMobile ? (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={onClose}
      PaperProps={{
        sx: {
          height: 'calc(100% - 80px)',
          top: 80
        }
      }}
    >
      <div className="flex w-full flex-col p-6">
        <div>
          {(title || renderTitle) && (
            <div
              className="mb-4 flex w-full items-center pb-md"
              style={{
                borderBottom: 1,
                borderColor: palette.divider,
                color: palette.text.primaryAlt1
              }}
            >
              {renderTitle ? (
                <>{renderTitle()}</>
              ) : (
                <p style={{ ...typography.subtitle1 }}>{title}</p>
              )}
            </div>
          )}
          <div>{children}</div>
        </div>
        <div>
          {/* Action buttons */}
          <div className="mt-12 flex w-full justify-end">
            {buttonsToRender.map((button) => {
              return (
                <div className="ml-md" key={button.key}>
                  <Button
                    disabled={button.disabled || false}
                    variant={getButtonVariation(button)}
                    color={getButtonColor(button)}
                    onClick={button.onClick}
                    loading={button.loading}
                  >
                    {button.label}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  ) : (
    <MDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      disableEscapeKeyDown={disableEscKey}
      slotProps={{ backdrop: { sx: { background: 'transparent' } } }}
      PaperProps={{
        sx: {
          ...paperSx,
          minWidth: minWidth,
          maxWidth: maxWidth,
          backgroundColor: palette.background.neutral,
          border: 1,
          borderColor: palette.border.main
        }
      }}
    >
      <div className="flex w-full flex-col flex-nowrap">
        <div className="p-0">
          <div className="flex w-full flex-nowrap items-start justify-between">
            <div className="flex w-full flex-col pl-8 pt-6">
              <div className="mt-0">
                {renderTitleOverline && renderTitleOverline()}
              </div>
              <div className="flex items-center">
                <div className="grow">
                  {renderTitle ? (
                    <>{renderTitle()}</>
                  ) : (
                    <p style={{ ...typography.subtitle1 }}>{title}</p>
                  )}
                </div>
                {toggles && (
                  <div className="mr-md">
                    <MultiSwitch
                      options={toggles.modes.map((mode) => {
                        return mode as string;
                      })}
                      disabled={disableToggles}
                      activeOption={toggles.selectedMode as string}
                      setActiveOption={(option) => {
                        toggles.setSelectedMode(option as T);
                      }}
                    />
                  </div>
                )}
                <div className="ml-0 translate-y-0">
                  {activeBranchIdentifierState &&
                    !disableActiveBranch && (
                      <>
                        <BranchSelector
                          required
                          activeBranchIdentifier={
                            activeBranchIdentifier
                          }
                          setActiveBranchIdentifier={
                            setActiveBranchIdentifier
                          }
                          getIsValidBranch={getIsValidBranch}
                          disableClear
                          prefixText={'Location: '}
                          setBranch={setActiveBranch}
                        />
                      </>
                    )}
                  {activeGoalState && !disableActiveGoal && (
                    <GoalSelector
                      required
                      activeGoal={activeGoal}
                      setActiveGoal={setActiveGoal}
                      disableClear
                      prefixText={'Goal: '}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="ml-4 mr-6 mt-6">
              <IconButton
                aria-label="close"
                onClick={onClose}
                tabIndex={1}
              >
                <X />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="mt-4 px-8 pb-8">{children}</div>
        {(backButton || buttonsToRender?.length > 0) && (
          <div
            className="p-8 pt-0"
            style={{
              // mt: buttons.length > 0 ? 3 : 0,
              // borderTop: 1,
              borderColor: palette.border.main
            }}
          >
            <div
              className="flex w-full flex-nowrap items-center"
              style={{
                justifyContent:
                  backButton || altButtons.length > 0
                    ? 'space-between'
                    : 'flex-end'
              }}
            >
              {(backButton || altButtons.length > 0) && (
                <div className="flex w-full">
                  {backButton && (
                    <div className="mr-md">
                      <Button
                        variant="outline"
                        onClick={() => backButton.onClick()}
                      >
                        {backButton.label}
                      </Button>
                    </div>
                  )}
                  {altButtons.map((button) => {
                    return (
                      <div className="mr-4" key={button.key}>
                        <Button
                          disabled={button.disabled || false}
                          variant={getButtonVariation(button)}
                          color={getButtonColor(button)}
                          onClick={button.onClick}
                        >
                          {button.label}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
              {buttonsToRender.map((button) => {
                return (
                  <div className="ml-md" key={button.key}>
                    <Button
                      disabled={button.disabled || false}
                      variant={getButtonVariation(button)}
                      color={getButtonColor(button)}
                      onClick={button.onClick}
                      loading={button.loading}
                    >
                      {button.label}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </MDialog>
  );
}

import { Menu } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { ButtonType } from 'src/@types/button';
import Icon from 'src/components/core/atoms/Icon';
import IconButton from 'src/components/core/atoms/IconButton';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import { Button } from 'src/components/shad-base/button';
import {
  Drawer,
  DrawerContent
} from 'src/components/shad-base/drawer';
import { ROUTES, TOPBAR_HEIGHT_PX } from 'src/config';
import useAuthPersistStore, {
  AuthStoreType
} from 'src/hooks/store/useAuthPersistStore';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { platformItems } from 'src/navigation';
import { getPalette } from 'src/theme/palette';
import { getListItemKey } from 'src/utils/format';

// ----------------------------------------------------------------------

export default function DefaultMobileTopbar({
  mainButtons,
  actionButtons
}: {
  mainButtons: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[];
  actionButtons: ButtonType[];
}) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  const router = useRouter();
  const { authState } = useAuthPersistStore(
    (store: AuthStoreType) => ({
      authState: store.authState
    })
  );

  const [navigationMenuOpen, setNavigationMenuOpen] = useState(false);
  return (
    <>
      <div
        className="flex h-full flex-nowrap items-center justify-between bg-background px-md"
        style={{
          height: TOPBAR_HEIGHT_PX,
          minHeight: TOPBAR_HEIGHT_PX
        }}
      >
        <div
          onClick={() => {
            authState.isAuthenticated
              ? router.push(ROUTES.DASHBOARD)
              : router.push(ROUTES.ROOT);
          }}
          className="scale-75"
        >
          <Icon />
        </div>
        <div>
          <IconButton
            onClick={() => {
              setNavigationMenuOpen(true);
            }}
          >
            <Menu style={{ color: palette.common.black }} />
          </IconButton>
        </div>
      </div>
      <Drawer
        open={navigationMenuOpen}
        onOpenChange={(open) => setNavigationMenuOpen(open)}
      >
        <DrawerContent>
          <div className="flex h-full flex-col justify-between  p-lg">
            <div>
              <div className="flex flex-col flex-nowrap">
                {/* Product Accordions */}
                <Accordion type="single" collapsible>
                  {platformItems.map((platformItem, index) => {
                    return platformItem.comingSoon ? (
                      <div className="flex flex-nowrap items-center justify-between rounded-md bg-card p-md">
                        <p>{platformItem.name}</p>
                        <p className="ml-md text-sm text-primary ">
                          Soon
                        </p>
                      </div>
                    ) : (
                      <AccordionItem
                        value={platformItem.name}
                        key={getListItemKey(index)}
                        className={'mb-md'}
                      >
                        <AccordionTrigger className="rounded-md bg-card px-md">
                          <p>{platformItem.name}</p>
                        </AccordionTrigger>

                        <AccordionContent>
                          <div className="flex flex-col ">
                            {platformItem.sections.map(
                              (section, index) => {
                                return (
                                  <div
                                    key={getListItemKey(index)}
                                    className={
                                      'mt-md cursor-pointer rounded-md p-md hover:bg-card'
                                    }
                                    onClick={() =>
                                      router.push(section.path)
                                    }
                                  >
                                    <p>{section.label}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
                {/* Main Buttons */}
                {mainButtons.map((button, index) => {
                  return (
                    <div
                      key={getListItemKey(index)}
                      className={
                        'mt-md flex cursor-pointer rounded-md bg-card p-md hover:bg-card/50 '
                      }
                      onClick={() =>
                        router.push(button.component.path)
                      }
                    >
                      <p>{button.component.name}</p>
                    </div>
                  );
                })}
                {/* Team and Contact */}
                <div>
                  <div className="mt-md flex flex-col">
                    <div
                      className="flex cursor-pointer rounded-md bg-card p-md hover:bg-card/50"
                      onClick={() => router.push(ROUTES.COMPANY.TEAM)}
                    >
                      <p>Team</p>
                    </div>
                    <div
                      className="mt-md flex cursor-pointer rounded-md bg-card p-md hover:bg-card/50"
                      onClick={() =>
                        router.push(ROUTES.COMPANY.CONTACT)
                      }
                    >
                      <p>Contact</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Action Buttons */}
            <div className="pb-md">
              <div className="flex flex-col flex-nowrap">
                {actionButtons.map((button, index) => {
                  return (
                    <Button
                      onClick={() => router.push(button.path)}
                      key={getListItemKey(index)}
                      className="mt-md"
                      variant={
                        button.variation === 'secondary'
                          ? 'outline'
                          : 'default'
                      }
                    >
                      {button.name}
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}

import {
  ClickAwayListener,
  InputBase,
  MenuItem,
  Popper
} from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/shad-base/button';
import Checkbox from 'src/components/core/atoms/Checkbox';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { useDisableEscKeyStore } from './Dialog';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';
import { cn } from '@/lib/utils';
import { ChevronDown } from 'lucide-react';
import IconButton from '../atoms/IconButton';

export type OptionsType = { [key: string]: string }; // { identifier: name }
export type PlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'top-end'
  | 'top-start'
  | 'top';

type SelectPopperProps = {
  value?: string;
  options: OptionsType;
  onExit?: () => void;
  selectedOptionsState?: [OptionsType, (value: OptionsType) => void];
  onChange: (value: string) => void;
  isOpenState?: [boolean, (value: boolean) => void];
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void;
  buttonCallback?: (
    selectedOptions: OptionsType,
    setSelectedOptions
  ) => void;
  renderButtonText?: (selectedOptions: OptionsType) => string;
  hideSearchBar?: boolean;
  renderAnchorEl?: (value: string) => JSX.Element;
  multiSelect?: boolean;
  minWidth?: number;
  maxHeight?: number;
  initialSelectedOptions?: OptionsType;
  disableCloseOnButtonClick?: boolean;
  disabled?: boolean;
  renderOption?: (key: string) => JSX.Element;
  containerStyle?: CSSProperties;
  loading?: boolean;
  backgroundColor?: string;
  stopPropagation?: boolean;
  placement?: PlacementType;
};

export default function SelectPopper({
  value,
  options,
  selectedOptionsState = null,
  onExit = () => null,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  onChange,
  isOpenState = [null, () => null],
  renderAnchorEl,
  disabled = false,
  hideSearchBar = false,
  renderOption,
  initialSelectedOptions,
  renderButtonText,
  disableCloseOnButtonClick,
  buttonCallback,
  multiSelect = false,
  containerStyle = null,
  minWidth = 200,
  maxHeight = 400,
  loading = false,
  backgroundColor = 'background.neutral',
  stopPropagation = false,
  placement = 'bottom-start'
}: SelectPopperProps) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  const mainRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isOpen, setIsOpen] = isOpenState;

  const open = Boolean(
    isOpen !== null ? isOpen && anchorEl : anchorEl
  );

  // If multiselect
  let selectedOptions = null;
  let setSelectedOptions = null;
  if (selectedOptionsState !== null) {
    // If selectedOptionsState is passed in, use that
    [selectedOptions, setSelectedOptions] = selectedOptionsState;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [selectedOptions, setSelectedOptions] = useState<OptionsType>(
      initialSelectedOptions
    );
  }

  // TODO: this is awkward because we are checking for the presence of a key
  // This means there is some duplicated logic between this and initialSelectedOptions
  function isSelected(value: string) {
    return Object.keys(selectedOptions || {}).includes(value);
  }

  const [filteredOptions, setFilteredOptions] =
    useState<OptionsType>(options);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSelectedOptions(initialSelectedOptions);
    setFilteredOptions(options);
    setSearchQuery('');
  }, [open, options]);

  const { setDisableEscKey } = useDisableEscKeyStore((store) => ({
    setDisableEscKey: store.setDisableEscKey
  }));

  const handleClick = () => {
    const disableAnchor = anchorEl || isOpen === false;

    if (disableAnchor) {
      setDisableEscKey(false);
    } else {
      setDisableEscKey(true);
    }

    setAnchorEl(disableAnchor ? null : !disabled && mainRef.current);
  };

  useEffect(() => {
    if (isOpen !== null) {
      handleClick();
    }
  }, [isOpen]);

  const handleOptionClick = (key: string) => {
    onChange(key);
    if (!multiSelect) {
      onClose();
    } else {
      // update new selected options
      const newSelectedOptions = { ...selectedOptions };
      if (isSelected(key)) {
        delete newSelectedOptions[key];
      } else {
        newSelectedOptions[key] = options[key];
      }
      setSelectedOptions({ ...newSelectedOptions });
    }
  };

  const BUTTON_HEIGHT = 62;
  const SEARCH_BAR_HEIGHT = 60;
  const maxContentHeight =
    maxHeight -
    (buttonCallback ? BUTTON_HEIGHT : 0) -
    (!hideSearchBar ? SEARCH_BAR_HEIGHT : 0);

  const refs = useRef({});
  const inputRef = useRef(null);
  const scrollableAreaRef = useRef(null);
  const activeRefIdx = useRef(-1);

  // Add an up/down key listener that is active when the popper is open
  const handleKeyDown = (e) => {
    const keys = Object.keys(filteredOptions);
    const idx = activeRefIdx.current;

    // check if escape key was pressed
    if (e.code === 'Escape') {
      onClose();
      // prevent the event from bubbling up
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    } else if (e.key === 'ArrowDown') {
      setIsMouseMoving(false);
      if (idx < keys.length - 1) {
        activeRefIdx.current = idx + 1;

        if (activeRefIdx.current === 0) {
          scrollableAreaRef.current.style.overflowY = 'clip';
        }

        refs.current[keys[activeRefIdx.current]].focus({
          preventScroll: true
        });
        if (activeRefIdx.current === 0) {
          setTimeout(() => {
            scrollableAreaRef.current.style.overflowY = 'scroll';
          }, 100);
        }

        // scroll into view at the bottom of the scrollable area
        // const activeRef = refs.current[keys[activeRefIdx.current]];
      }
    } else if (e.key === 'ArrowUp') {
      setIsMouseMoving(false);
      if (idx > 0) {
        activeRefIdx.current = idx - 1;
        refs.current[keys[activeRefIdx.current]].focus();
      } else {
        activeRefIdx.current = -1;
        inputRef.current.focus();
      }
    }
  };
  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [open, filteredOptions, value]);

  const onClose = () => {
    activeRefIdx.current = -1;
    setIsOpen(false);
    setDisableEscKey(false);
    onExit && onExit();
    setAnchorEl(null);
  };

  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const backgroundColorProp =
    backgroundColor == 'background.neutral'
      ? palette.background.neutral
      : backgroundColor;

  const popper = (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{
        // Note that some parts of the app have higher stacking contexts and will
        // appear above this popper. This unintentional and should be fixed.
        zIndex: 1000000,
        backgroundColor: backgroundColorProp,
        border: 1,
        borderColor: palette.border.main,
        borderRadius: 1,
        minWidth,
        maxHeight,
        boxShadow: '0px 0px 30px 5px ' + palette.background.boxShadow
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]}
      placement={placement}
    >
      <div
        className="size-full min-w-full"
        style={{
          maxHeight: maxHeight
        }}
        onMouseEnter={(e) => {
          onMouseEnter && onMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          onMouseLeave && onMouseLeave(e);
        }}
      >
        {/* Search bar */}
        {!hideSearchBar && (
          <div>
            <InputBase
              autoFocus
              inputRef={inputRef}
              onFocus={() => {
                activeRefIdx.current = -1;
              }}
              value={searchQuery}
              disabled={loading}
              fullWidth
              placeholder={'Search...'}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                const newFilteredOptions = {};

                Object.keys(options).forEach((key) => {
                  if (
                    key
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    options[key]
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  ) {
                    newFilteredOptions[key] = options[key];
                  }
                });
                setFilteredOptions(newFilteredOptions);
              }}
              inputProps={{
                sx: {
                  fontSize: typography.caption.fontSize,
                  fontFamily: typography.caption.fontFamily,
                  // fontWeight: typography.caption.fontWeight,
                  borderBottom: 1,
                  borderColor: palette.divider,
                  pl: 2,
                  py: 1.5,
                  // mb: 1,
                  '&.Mui-disabled': {
                    WebkitTextFillColor: `${palette.common.white} !important`
                  }
                }
              }}
            />
          </div>
        )}

        {loading === true &&
          ['skeleton-1', 'skeleton-2', 'skeleton-3'].map(
            (key, idx) => {
              return (
                <div
                  className={cn(
                    'w-full px-2',
                    idx === 0 ? 'mt-0' : 'mt-2'
                  )}
                  key={key}
                >
                  <Skeleton height="32px" />
                </div>
              );
            }
          )}

        {/* Content */}
        <div>
          <div
            ref={scrollableAreaRef}
            // container
            className="flex flex-col flex-nowrap overflow-y-scroll"
            style={{
              maxHeight: maxContentHeight
            }}
            onMouseMoveCapture={() => {
              setIsMouseMoving(true);
            }}
          >
            {Object.keys(filteredOptions || {}).length > 0 ? (
              <>
                <div className="pt-2" />
                {Object.keys(filteredOptions).map((key, idx) => {
                  return (
                    !loading && (
                      <MenuItem
                        ref={(ref) => {
                          refs.current[key] = ref;
                        }}
                        onMouseEnter={() => {
                          isMouseMoving &&
                            (activeRefIdx.current = idx);
                        }}
                        key={key}
                        onClick={() => {
                          handleOptionClick(key);
                          if (!multiSelect) {
                            onClose();
                          }
                        }}
                        sx={{
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          color:
                            value === key && !loading
                              ? palette.primary.main
                              : palette.text.primary,
                          '&:hover': {
                            backgroundColor: isMouseMoving
                              ? palette.background.default
                              : backgroundColorProp
                          },
                          '&:focus': {
                            backgroundColor:
                              palette.background.default
                          },
                          borderRadius: 1,
                          mx: 1,
                          px: 1
                        }}
                      >
                        {multiSelect && (
                          <div>
                            <Checkbox
                              onClick={() => {
                                handleOptionClick(key);
                              }}
                              selected={isSelected(key)}
                            />
                          </div>
                        )}
                        <div
                          className="grow pl-2"
                          style={{
                            maxWidth: 'calc(100% - 45px)'
                          }}
                        >
                          {renderOption ? (
                            renderOption(key)
                          ) : (
                            <p
                              style={{ ...typography.body2 }}
                              color={
                                value === key
                                  ? palette.primary.main
                                  : 'inherit'
                              }
                            >
                              {options[key]}
                            </p>
                          )}
                        </div>
                      </MenuItem>
                    )
                  );
                })}
                <div className="pt-2" />
              </>
            ) : (
              <div className="px-4 pb-2">
                <p style={{ ...typography.caption }}>No results.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      {buttonCallback && (
        <div
          className="p-2"
          style={{
            borderTop: 1,
            borderTopColor: palette.border.main,
            height: BUTTON_HEIGHT
          }}
          onMouseDown={(e) => {
            buttonCallback(selectedOptions, setSelectedOptions);
            if (!disableCloseOnButtonClick) {
              onClose();
            }
            e.stopPropagation();
          }}
        >
          <Button variant="outline" className="w-full">
            {renderButtonText
              ? renderButtonText(selectedOptions)
              : 'Save'}
          </Button>
        </div>
      )}
    </Popper>
  );

  return (
    <>
      {/* Anchor element */}
      <div
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          handleClick();
        }}
        style={containerStyle}
        ref={mainRef}
      >
        {renderAnchorEl ? (
          renderAnchorEl(value)
        ) : (
          <div className="flex w-full flex-nowrap items-center justify-between">
            <div>
              <p style={{ ...typography.body1 }}>{options[value]}</p>
            </div>
            <div>
              <IconButton disabled={disabled}>
                <ChevronDown />
              </IconButton>
            </div>
          </div>
        )}
      </div>

      {/* Popper */}
      {open ? (
        <ClickAwayListener onClickAway={onClose}>
          {popper}
        </ClickAwayListener>
      ) : (
        popper
      )}
    </>
  );
}

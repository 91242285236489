import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  CreateReportingPeriodInput,
  CreateReportingPeriodPayload
} from 'src/__apolloGenerated__/graphql';
import { DatePicker } from 'src/components/core/atoms/DatePicker';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import useMeasureStore from 'src/hooks/store/useMeasureStore';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';

export default function CreateReportingPeriodDialog({
  open,
  setOpen,
  activeEntityId = null,
  refetchData,
  onCancel = null
}: {
  open: boolean;
  setOpen: (newState) => void;
  activeEntityId: string;
  refetchData: () => void;
  onCancel?: () => void;
}) {
  const {
    setSelectedRangeType,
    setSelectedStartDate,
    setSelectedEndDate
  } = useMeasureStore((store) => {
    return {
      setSelectedRangeType: store.setSelectedRangeType,
      setSelectedStartDate: store.setSelectedStartDate,
      setSelectedEndDate: store.setSelectedEndDate
    };
  });

  const {
    setActiveReportingPeriodId,
    setActiveReportingPeriodStartDate,
    setActiveReportingPeriodEndDate
  } = useSettingsStore();

  const lastYear = dayjs().year() - 1;

  const firstDayOfLastYear = dayjs()
    .date(1)
    .year(lastYear)
    .month(0)
    .hour(0);
  const lastDayOfLastYear = dayjs()
    .date(31)
    .year(lastYear)
    .month(12)
    .hour(0);

  const firstDayOfLastYearFiscal = dayjs()
    .date(1)
    .year(lastYear - 1)
    .month(9)
    .hour(0);
  const lastDayOfLastYearFiscal = dayjs()
    .date(30)
    .month(8)
    .year(lastYear)
    .hour(0);

  const [variables, setVariables] = useState({
    startDate: dayjs().format(),
    endDate: dayjs().format()
  });

  const { mutate: createReportingPeriod } = useBackendMutation<
    { input: CreateReportingPeriodInput },
    CreateReportingPeriodPayload
  >({
    mutation: gql`
      mutation ReportingPeriodSelectorCreateReportingPeriod(
        $input: CreateReportingPeriodInput!
      ) {
        createReportingPeriod(input: $input) {
          ok
          entity {
            reportingPeriods {
              identifier
              startDate
              endDate
            }
          }
          reportingPeriod {
            identifier
            startDate
            endDate
          }
        }
      }
    `,
    callbacks: {
      onSuccess: (data) => {
        setActiveReportingPeriodId(data?.reportingPeriod?.identifier);
        setActiveReportingPeriodStartDate(
          new Date(data?.reportingPeriod?.startDate)
        );
        setActiveReportingPeriodEndDate(
          new Date(data?.reportingPeriod?.endDate)
        );
        setSelectedRangeType('Custom');
        setSelectedStartDate(
          new Date(data?.reportingPeriod?.startDate)
        );
        setSelectedEndDate(new Date(data?.reportingPeriod?.endDate));
        refetchData();
      }
    },
    errorHandlers: {}
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-screen-md">
        <div className="flex flex-col flex-nowrap items-center">
          <div className="pt-md">
            <h5>New Reporting Period</h5>
          </div>

          <p className="max-w-paragraph text-center text-muted">
            Add a new reporting period
          </p>
          <div className="mb-md ml-md mt-md">
            {/* <LearnButton /> */}
          </div>

          <div className="mb-lg mt-xl flex w-full flex-col items-center justify-between md:flex-row">
            <div>
              <DatePicker
                required
                size="lg"
                date={
                  variables.startDate
                    ? new Date(variables.startDate)
                    : new Date(firstDayOfLastYear.format())
                }
                minDate={new Date('2000-01-01')}
                maxDate={new Date('2050-01-01')}
                setDate={(newDate) => {
                  setVariables({
                    ...variables,
                    startDate: dayjs(newDate.setHours(0)).format()
                  });
                }}
                label="Start Date"
              />
            </div>
            <p>to</p>
            <div>
              <DatePicker
                required
                size="lg"
                date={
                  variables.endDate
                    ? new Date(variables.endDate)
                    : new Date(lastDayOfLastYear.format())
                }
                minDate={new Date('2000-01-01')}
                maxDate={new Date('2050-01-01')}
                setDate={(newDate) => {
                  setVariables({
                    ...variables,
                    endDate: dayjs(newDate.setHours(0)).format()
                  });
                }}
                label={'End Date '}
              />
            </div>
          </div>
          <div className="mb-xl flex w-full items-center gap-4">
            <Button
              variant="outline"
              onClick={() =>
                setVariables({
                  ...variables,
                  startDate: firstDayOfLastYear.format(),
                  endDate: lastDayOfLastYear.format()
                })
              }
            >
              Last Calendar Year
            </Button>
            <Button
              variant="outline"
              onClick={() =>
                setVariables({
                  ...variables,
                  startDate: firstDayOfLastYearFiscal.format(),
                  endDate: lastDayOfLastYearFiscal.format()
                })
              }
            >
              Last Financial Year
            </Button>
          </div>
          <div className="mt-xl flex w-full items-center justify-between">
            <Button
              variant="outline"
              onClick={
                onCancel ? () => onCancel() : () => setOpen(false)
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                createReportingPeriod({
                  variables: {
                    input: {
                      startDate: variables.startDate,
                      endDate: variables.endDate,
                      entityIdentifier: activeEntityId
                    }
                  }
                });
                setOpen(false);
              }}
            >
              Create
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
